import React from 'react'
import Header from '../../common/Header';
import FooterDesktop from '../../common/FooterDesktop';
import shop from './shop.jpeg';
import shikha from './shikha.jpeg';

const Aboutus = () => {
  return (
    <div>
        <Header />
        <section className='dark-bg about-section ptb-30'>
            <div className="container">
               <br /><br /><br />
                <div className="row d-flex align-items-center ">
                    <div className="col-sm-5 col-12">
                        <img src={shikha} alt="" className="img-fluid" />
                    </div>
                    <div className="col-sm-7 col-12">
                        <h1 className='mt-30'>MEET THE DIRECTOR</h1>
                        <p className=" mt-10 font-14">
Shikha Durlabhji, our founder, translated her love for wildlife into wildlife collectibles and accessories that evoke grandeur to take you on a visual vacation. Code Silver Wild's products are elegance redefined and are crafted exclusively for wildlife lovers and enthusiasts who wish to take a little bit of the wilderness back home.
<br /><br />
Be it home or office decor, jewellery, animal figurines, barware, travel accessories and more, we keep a wide collection of bespoke designs. We offer customizable designs to choose from for gifting your loved ones. Our designs are inspired by photographs taken by well-renowned wildlife photographers from across the globe and we take great pride in the finishing of each product.
<br /><br />

After completing school from Maharaja Sawai Man Singh Vidyalaya, Jaipur in 2004, Shikha joined Miranda House, University of Delhi. She read History in college. At Miranda House, Shikha organized a wide spectrum of activities – Inter-College festival (Tarikh), South-Asian Students Seminar Debate (Manzar). She was also President of the History Society and the Adventure Club. After graduating in 2008, she undertook a 6-month course in photography from Triveni Kala Sangam in Delhi.
<br /><br />

Though she still loves photography, she has realized that photography is not something she wishes to pursue professionally. After much deliberation, Shikha decided to foray into silver gift items. After several months of research, she launched her own specialty line (in Jaipur) called Code Silver on September 1, 2010.
<br /><br />

Shikha's affair with wildlife led to the birth of Code Silver Wild.</p>
                    </div>
                </div>
            </div>
        </section>

        <div className="section parallax">
  <div styel="padding-left:190px" className="para-div section">
    <div className="container">
    <div className="row">
    <div className="col-12">
    <br />
    <br />
    <br />
    <br />
    <br />
    <h1 style={{ color: "#fff !important", fontSize: 34, fontFamily: "big" }}>
      ABOUT CODE SILVER
    </h1>
    <br />
    <br />
   
    <br />
    </div>
    </div>
    </div>
  
  
   
  </div>
</div>
<section className='dark-bg about-section ptb-30'>
            <div className="container">
               <br /><br /><br />
                <div className="row d-flex align-items-center ">
                    <div className="col-sm-5 col-12">
                        <img src={shop} alt="" className="img-fluid" />
                    </div>
                    <div className="col-sm-7 col-12">
                        <h1 className='mt-30'>Code Silver Introduction</h1>
                        <p className=" mt-10 font-14">
                        Code Silver, the very name holds itself two elements- the modernity of today and the authenticity of silver. Established in 2010, Code Silver is the unique blend of contemporary style, and handcrafted heritage, with detailing or baariki that is reflected in every piece, created by hand.
<br /><br />
Exquisite silver and silver plated work, transcending form. Who said Silver was reduced to jewelry and dining ware?
<br /><br />
Well beyond the usual expectations of silver- the designs and products redefine the use of silver and its character. Silver is young, it is experimentative! And the way we think, and work with it is new too! With newest technology behind us and our imagination inspired by you, we have translated it into an unusual mélange of novelty. In the past 11 years, we have created formidable ensembles, in keeping with your sensibilities of clean modernity and a hint of tradition. So we’ve redefined jaalis! Our motifs are new! We have reimagined shapes, spaces, use and aesthetics! From silver bookmarks to tableware, to office accessories, to rakhis, to home furnishings, to ornate decorative items, we explore the magic of silver, each glorious piece at a time.
<br /><br />
These items make for excellent gifts on special occasions like weddings, birthdays and anniversaries. Moreover, they are in huge demand as corporate gifts by the Indian Corporate industry which delivers them to a large number of employees, Chairmen, friends and clients on occasions like Diwali, New Year, Rakhi etc.
<br /><br />
Code Silver introduces a dazzling collection of unusual designs, unique concepts and exquisite artwork – an unusual mélange of unusual items, objects d’art and one-of-a-kind pieces. Code Silver brings its ensemble of gift items in silver and silver plated – tableware, candle stands, tea light holders, photo frames, office accessories, lamps, religious products, vases, jewellery, Rakhis etc.
<br /><br />
Silver plating renders the item cost-effective because the cost of silver is escalating. Many prefer silver plated for gifting. Many products are customized against specific orders – both in silver and silver plated.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <FooterDesktop />
    </div>
  )
}

export default Aboutus