import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../../contexts";

const BottomNav = (props) => {
  const { cart } = useContext(CartContext);
  return (
    <div id="footer-bar" className="footer-bar footer-bar-colored">
      <Link to="/" className={props.page === "/" ? "active-nav" : ""}>
        <i className="bi bi-house font-24 "></i>
        <span>Home</span>
      </Link>
      <Link
        to="/login"
        className={props.page === "/profile" ? "active-nav" : ""}
      >
        <i className="bi bi-person font-24"></i>
        <span>Profile</span>
      </Link>

      <Link
        to="/AllCategories"
        className={props.page === "/AllCategories" ? "active-nav" : ""}
      >
        <i className="bi bi-shop"></i>
        <span>shop</span>
      </Link>

      <Link to="/cart" className={props.page === "/cart" ? "active-nav" : ""}>
        <i className="bi bi-cart3" style={{ position: "relative" }}>
          {cart && cart.length !== 0 ? (
            <div
              style={{
                height: "15px",
                width: "15px",
                borderRadius: "50%",
                backgroundColor: "#1F7D8C",
                color: "#fff",
                position: "absolute",
                top: "-5px",
                right: "20px",
                fontSize: "13px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {cart ? cart.length : 0}
            </div>
          ) : null}
        </i>
        <span>Cart</span>
      </Link>
    </div>
  );
};

export default BottomNav;
