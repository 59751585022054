import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
const CartTotal = ({ cart, handleCheckout, buttonsDisabled }) => {
  return (
    <div className="row">
      <div className="col-sm-7">
        <br />
      </div>

      <div className="col-sm-5">
        <table style={{ width: "100%" }} className="table">
          <tbody>
            <tr style={{ border: "none" }}>
              <td
                style={{ border: "none", textTransform: "uppercase" }}
                className="text-center"
              >
                <strong className="text-description">Sub-Total:</strong>
              </td>
              <td
                style={{ border: "none", textTransform: "uppercase" }}
                className="text-right text-description"
              >
                {"₹" +
                  _.sum(cart.map((cartItem) => cartItem.total_excluding_tax))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
            </tr>
            <tr style={{ border: "none" }}>
              <td
                style={{ border: "none", textTransform: "uppercase" }}
                className="text-center"
              >
                <strong className="text-description">GST:</strong>
              </td>
              <td
                style={{ border: "none", textTransform: "uppercase" }}
                className="text-right text-description"
              >
                {"₹" +
                  (
                    _.sum(cart.map((cartItem) => cartItem.total)) -
                    _.sum(cart.map((cartItem) => cartItem.total_excluding_tax))
                  )
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
            </tr>
            <tr style={{ border: "none" }}>
              <td
                style={{ border: "none", textTransform: "uppercase" }}
                className="text-center"
              >
                <strong className="text-gold">Total:</strong>
              </td>
              <td
                style={{ border: "none", textTransform: "uppercase" }}
                className="text-right text-gold"
              >
                <strong className="text-gold">
                  {" "}
                  {"₹" +
                    _.sum(cart.map((cartItem) => cartItem.total))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <div className="row">
          <div className="col-sm-12">
            <div className="buttons clearfix">
              <div className="pull-right">
                <Link to="">
                  <button
                    style={{ color: "#fff" }}
                    href="index.php?route=account/quotation/add"
                    className="btn btn-primary btn-gold  btn-outline"
                  >
                    Login
                  </button>
                </Link>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <button
                  style={{ color: "#fff" }}
                  className="btn btn-primary btn-gold"
                  onClick={() => handleCheckout()}
                  disabled={buttonsDisabled}
                >
                  {buttonsDisabled ? "Please Wait..." : "Checkout"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartTotal;
