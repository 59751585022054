import React from "react";
import QuantityInputButtons from "../../quantityInputButtons/QuantityInputButtons";

const ProductInfo = ({
  productDetails,
  productPrice,
  quantity,
  handleIncrement,
  handleDecrement,
  handleCustomQuantityInput,
  buttonsDisabled,
  handleAddToCart,
}) => {
  return (
    <>
      {productDetails ? (
        <>
          <section className="product-info">
            <div className="row">
              <div className="col-12">
                <p className="product-name mb-0 font-24 golden-color mt-10">
                  {productDetails.name}
                </p>
                <button className="btn btn-counter wishlist-btn">
                  <i className="bi bi-heart font-16 text-gold"></i>
                </button>
                <p className="product-price mb-0 font-18 text-white mt-5px">
                  {"₹" +
                    productPrice
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>

                <p className="mb-0 mt-10">Quantity</p>

                <QuantityInputButtons
                  quantity={quantity}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  handleCustomQuantityInput={handleCustomQuantityInput}
                ></QuantityInputButtons>

                {/* <p className="mt-20 mb-0">Select Size</p>
                <select name="" id="" className="form-control select-box">
                  <option value="">Test</option>
                  <option value="">Test</option>
                  <option value="">Test</option>
                </select> */}
              </div>
            </div>
            <div className="row">
              <div className="col-6 mt-20">
                <button
                  className="btn btn-primary btn-gold w-100 "
                  disabled={buttonsDisabled}
                  onClick={() => handleAddToCart()}
                >
                  <i className="bi bi-cart3"></i>{" "}
                  {buttonsDisabled ? "Added to Cart" : "Add to Cart"}
                </button>
              </div>
              <div className="col-6 mt-20">
                <button className="btn btn-primary btn-gold w-100 btn-outline">
                  <i className="bi bi-lightning"></i> BUY NOW
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div
                  className="accordion accordion-m border-0"
                  id="accordion-group-5"
                >
                  <div className="accordion-item">
                    <button
                      className="accordion-button px-0 ps-1 collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion5-1"
                      aria-expanded="true"
                    >
                      <span className="font-600 font-13 text-description">
                        Product Description
                      </span>
                      <i className="bi bi-plus font-20 text-description" />
                    </button>
                    <div
                      id="accordion5-1"
                      className="accordion-collapse"
                      data-bs-parent="#accordion-group-5"
                    >
                      <p className="mb-0 pb-3 text-description">
                        {productDetails.description.replace(/<[^>]*>?/gm, "")}
                      </p>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <button
                      className="accordion-button px-0 ps-1 collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion5-2"
                    >
                      <span className="font-600 font-13 text-description">
                        Product Care
                      </span>
                      <i className="bi bi-plus font-20" />
                    </button>
                    <div
                      id="accordion5-2"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion-group-5"
                    >
                      <p className="mb-0 pb-3 text-description">
                        Do not scrub any of the products.
                        <br />
                        To clean this, you only need to wipe it with a damp
                        cloth
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : null}
    </>
  );
};

export default ProductInfo;
