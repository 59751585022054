import React, { useState, useRef } from "react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CartContext } from "../../contexts";
import logo from "../../imgs/logo/logo.jpeg";
import SearchForm from "./SearchForm";
import SideBar from "./SideBar";
import "./header.css";
import DropMenu from "./DropMenu";

function showSearch() {
  if (window.innerWidth < 1250) {
    document.getElementById("search-form").style.display = "flex";
  }
}

function menuOpen() {
  if (window.innerWidth < 750) {
    document.getElementById("side-bar").style.left = "0px";
    document.getElementById("sidebar-bg").style.display = "inline";
  }
}

const HeaderWhite = () => {
  const { cart } = useContext(CartContext);

  const [searchTerm, setSearchTerm] = useState("");

  const searchInput = useRef(null);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (window.innerWidth < 1250) {
      document.getElementById("search-form").style.display = "flex";
    } else if (searchInput.current.value) {
      navigate(`/search/${searchInput.current.value}`);
    }
  };

 const [isShown,setMenu] = useState(false);

  return (
    <>
      <div className="header-bar header-fixed header-app dark-header">
        <SearchForm />
        <div className="container header-container mt-10">
          <div className="topStrip hide-mob"></div>

          <div className="row d-flex align-items-center mobile-margin">
            <div className="col-2 col-sm-2">
              <Link to="../">
                <img src={logo} alt="" className="img-logo" />
              </Link>
            </div>
            <div className="col-sm-7 col-5">
              <nav className="desktop_nav">
                <span id="drop_here" onMouseEnter={()=>setMenu(true)} onMouseLeave={()=>setMenu(false)}>
                  <Link to="#" className="golden-color">
                    Shop
                    <DropMenu visible={isShown}/>
                  </Link>
                </span>
                <span>
                  <Link to="../story" className="golden-color">
                    Story
                  </Link>
                </span>
                <span>
                  <Link to="../contact" className="golden-color">
                    Contact
                  </Link>
                </span>
                <span>
                  <a href="https://wild.codesilver.in" className="golden-color">
                    Code Silver Wild
                  </a>
                </span>
              </nav>
            </div>
            <div className="col-5 col-sm-3">
              <i
                style={{ color: "#fff", marginRight: "0px" }}
                onClick={() => menuOpen()}
                className="bi bi-list cursor-pointer font-24 float-right mr-10 hide-desktop golden-color"
              ></i>
              &nbsp;&nbsp;
              <Link to="../Cart" className="golden-color">
                <i
                  style={{ color: "#fff", position: "relative" }}
                  className="bi bi-cart3 font-24 float-right mr-10 hide-phone golden-color"
                >
                  <div className="cart-badge">{cart ? cart.length : 0}</div>
                </i>
                &nbsp;&nbsp;
              </Link>
              <Link to="../login" className="golden-color">
                <i
                  style={{ color: "#fff" }}
                  className="bi bi-person-circle font-20 float-right mr-10 golden-color"
                ></i>
              </Link>
              <i
                style={{ color: "#fff" }}
                onClick={handleSearch}
                className="bi bi-search cursor-pointer font-18 float-right mr-10 golden-color"
              ></i>
              &nbsp;&nbsp;
              <input
                ref={searchInput}
                onChange={(e) => handleChange(e)}
                value={searchTerm}
                type="text"
                placeholder="Search"
                className="search-desktop"
              />
            </div>
          </div>
        </div>
        <SideBar />
      </div>
    </>
  );
};
export default HeaderWhite;
