import React from "react";
import upi from "./UPI.png";
import PaymentForm from "./PaymentForm";

const PaymentMethod = ({ payUForm, handleChange, setPayUForm }) => {
  return (
    <div className="paymentbox ">
      <div className="row">
        <div className="col-12">
          <p className="mt-30 mb-10 font-600 text-description font-18">
            Choose Payment Method
          </p>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="btn btn-border payment-btn w-100">
              <p className="text-description font-600 font-16 mb-0">
                <i className="bi bi-credit-card-2-back"></i> &nbsp; Debit Card
              </p>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="Pg"
                  id="Pg1"
                  value="DC"
                  checked={payUForm.Pg === "DC"}
                  onChange={(e) => {
                    handleChange(e, payUForm, setPayUForm);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="btn btn-border payment-btn w-100 ">
              <p className="text-description font-600 font-16 mb-0">
                <i className="bi bi-credit-card-fill"></i> &nbsp; Credit Card
              </p>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="Pg"
                  id="Pg1"
                  value="CC"
                  checked={payUForm.Pg === "CC"}
                  onChange={(e) => {
                    handleChange(e, payUForm, setPayUForm);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 mt-20">
            <div className="btn btn-border payment-btn w-100 ">
              <p className="text-description font-600 font-16 mb-0">
                <i className="bi bi-bank"></i> &nbsp; Net Banking
              </p>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="Pg"
                  id="Pg1"
                  value="NB"
                  checked={payUForm.Pg === "NB"}
                  onChange={(e) => {
                    handleChange(e, payUForm, setPayUForm);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-6 mt-20">
            <div className="btn btn-border payment-btn w-100 ">
              <p className="text-description font-600 font-16 mb-0">
                <img src={upi} alt="" className="upi-icon" /> &nbsp; UPI
              </p>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="Pg"
                  id="Pg1"
                  value="UPI"
                  checked={payUForm.Pg === "UPI"}
                  onChange={(e) => {
                    handleChange(e, payUForm, setPayUForm);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 mt-20">
            <div className="btn btn-border payment-btn w-100 ">
              <p className="text-description font-600 font-16 mb-0">
                <i className="bi bi-cash"></i> &nbsp; EMI
              </p>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="Pg"
                  id="Pg1"
                  value="EMI"
                  checked={payUForm.Pg === "EMI"}
                  onChange={(e) => {
                    handleChange(e, payUForm, setPayUForm);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-6 mt-20">
            <div className="btn btn-border payment-btn w-100 ">
              <p className="text-description font-600 font-16 mb-0">
                <i className="bi bi-wallet2"></i> &nbsp; Others
              </p>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="Pg"
                  id="Pg1"
                  value="OT"
                  checked={payUForm.Pg === "OT"}
                  onChange={(e) => {
                    handleChange(e, payUForm, setPayUForm);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PaymentForm payUForm={{ payUForm }} />
    </div>
  );
};

export default PaymentMethod;
