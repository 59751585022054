import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CatalogContext } from "../../contexts";

const Mostwanted = () => {
 
  const { allCategories } = useContext(CatalogContext);

 
  return (
    <section className="most-wanted dark-section pt-50 off-bg">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h3>Explore The Collection</h3>
            <p className="text-description">
            Wide Range Of Silver Artifacts To Choose From.
            </p>
          </div>
        </div>
        <div className="row">
          {allCategories.map((category) => {
            return (
            <>
              <div className="test_box box-01 col-4">
              <div
                className="inner"
                style={{
                  backgroundImage: `url(${category.image})`
                }}
              >
                
              </div>
              <div className="text-center">
                  
                  <h1 className="test_title">{category.name}</h1>
               
                <Link to={`/categories/${category.category_id}`} className="test_link">
                  Shop Now
                </Link>
              </div>
              </div>
           
              </>
            );
          })}
        </div>
        

        <br />
      </div>
    </section>
  );
};

export default Mostwanted;
