import React from "react";
import Header from "../../common/Header";
import FooterDesktop from "../../common/FooterDesktop";
import ConfrimBox from "./ConfrimBox";
import PaymentMethod from "./PaymentMethod";

const Confirm = (props) => {
  return (
    <div>
      <Header />
      <section className="mh-70">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="mt-100">Confrim Order</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <PaymentMethod {...props} />
            </div>
            <div className="col-6">
              <ConfrimBox {...props} />
            </div>
          </div>
        </div>
      </section>
      <FooterDesktop />
    </div>
  );
};

export default Confirm;
