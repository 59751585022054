import React from "react";

const PaymentForm = ({ payUForm }) => {
  console.log(payUForm);
  return (
    <div>
      <form
        method="post"
        action="https://secure.payu.in/_payment"
        style={{ display: "none" }}
        id="payment_form"
      >
        <div id="leftContainerFormValue">
          <label htmlFor="formkey">key:</label>
          <input
            type="text"
            id="s2sformkeyNonSeamless"
            name="key"
            value={payUForm.key}
            className="inputformModifiedLeft"
            required="required"
            readOnly
          />
        </div>
        <div id="rightContainerFormValue">
          <label htmlFor="txnid">txnid:</label>
          <input
            type="text"
            id="txnidNonSeamless"
            name="txnid"
            value={payUForm.txnid}
            className="inputformModifiedRight"
            required="required"
            readOnly
          />
          <br />
        </div>
        <div id="leftContainerFormValue">
          <label htmlFor="amount">amount:</label>
          <input
            type="text"
            id="amountNonSeamless"
            name="amount"
            value="1233"
            className="inputformModifiedLeft"
            required="required"
            readOnly
          />
        </div>
        <div id="rightContainerFormValue">
          <label htmlFor="fname">firstname:</label>
          <input
            type="text"
            id="fnameNonSeamless"
            name="firstname"
            value={payUForm.firstname}
            className="inputformModifiedRight"
            required="required"
            readOnly
          />
          <br />
        </div>
        <div id="leftContainerFormValue">
          <label htmlFor="email">email:</label>
          <input
            type="text"
            id="emailNonSeamless"
            name="email"
            value={payUForm.email}
            className="inputformModifiedLeft"
            required="required"
            readOnly
          />
        </div>
        <div id="rightContainerFormValue">
          <label htmlFor="phone">phone:</label>
          <input
            type="phone"
            id="phoneNonSeamless"
            name="phone"
            value={payUForm.phone}
            className="inputformModifiedRight"
          />
          <br />
        </div>
        <div id="leftContainerFormValue">
          <label htmlFor="productinfo">productinfo:</label>
          <input
            type="text"
            id="productinfoNonSeamless"
            name="productinfo"
            value={payUForm.productinfo}
            className="inputformModifiedLeft"
            required="required"
            readOnly
          />
        </div>
        <div id="rightContainerFormValue">
          <label htmlFor="surl">surl:</label>
          <input
            type="text"
            id="surlNonSeamless"
            name="surl"
            className="inputformModifiedRight"
            value={payUForm.surl}
            required="required"
            readOnly
          />
          <br />
        </div>
        <div id="leftContainerFormValue">
          <label htmlFor="furl">furl:</label>
          <input
            type="text"
            id="furlNonSeamless"
            name="furl"
            value={payUForm.furl}
            className="inputformModifiedLeft"
            required="required"
            readOnly
          />
        </div>
        <div id="rightContainerFormValue">
          <label htmlFor="hash">hash:</label>
          <input
            type="text"
            id="hashforNonSeamless"
            name="hash"
            value={payUForm.hash}
            className="inputformModifiedRight"
            required="required"
            readOnly
          />
          <br />
          <br />
        </div>
        <input type="submit" defaultValue="Submit" id="formsubmitbtn" />
        <br />
        <br />
      </form>
    </div>
  );
};

export default PaymentForm;
