import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CatalogContext } from "../../contexts";
import bg from "./bg.jpg";
import Carousel from "react-multi-carousel";
import TrendingProductCard from "./TrendingProductCard";


const responsive = {
  mobile: {
    breakpoint: { max: 650, min: 0 },
    items: 1.3,
  },
};

const WideRange = () => {
  return (
    <section
      className="most-wanted dark-section"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="container ">
        <div className="row">
          <div className="col-12 text-center">
            <br />
            <h2>Top Trending Products</h2>
            <p>Selection of top trending products at Code Silver.</p>
          </div>
        </div>
        <br />

      <div className="col-12">
      <Carousel
          responsive={responsive}
          showDots={false}
          arrows={true}
          className="trending-slider p-0"
        >
          
          <TrendingProductCard 
            image="https://codesilver.in/image/catalog/large/CSTW152.jpg" 
            link="../product/3089" 
            name="COPPER NAPKIN RINGS" 
            price="3,528"/>
            <TrendingProductCard 
            image="https://codesilver.in/image/catalog/large/CSTLH179.jpg" 
            link="../product/2807" 
            name="DECORATED TEA LIGHT HOLDER" 
            price="5,320"/>
             <TrendingProductCard 
            image="https://codesilver.in/image/catalog/large/CSTLH198.jpg" 
            link="../product/2821" 
            name="DECORATED TEA LIGHT HOLDER" 
            price="4,592"/>
            <TrendingProductCard 
            image="https://codesilver.in/image/catalog/large/CSTW051.jpg" 
            link="../product/2994" 
            name="DECORATED BOWL" 
            price="4,480"/>
  

        </Carousel>
      </div>

        <div className="col-12 text-center">
          <br />
          <Link to="categories/101">
          <button className="btn btn-primary">
            View All Products &nbsp;&nbsp;
            <i className="bi bi-arrow-right-circle-fill" />
          </button>
          </Link>
          <br />
          <br />
          <br />
        </div>

        <br />
        <br />
      </div>
    </section>
  );
};

export default WideRange;
