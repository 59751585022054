import React from "react";
import Header from "../../common/Header";
import FooterDesktop from "../../common/FooterDesktop";
import { Link } from "react-router-dom";
import Select from "react-select";
import CountryCodes from "./CountryCodes";

const Billing = ({
  handleChange,
  guestUser,
  setGuestUser,
  handleCountryChange,
  countries,
  handleZoneChange,
  zones,
  createGuestUser,
  buttonsDisabled,
  cc,
  handleCountryCodeChange,
}) => {
  return (
    <div>
      {/* <Header /> */}
      <section className="mh-70 mb-30">
        <div className="container mt-100">
          <div className="row text-center ">
            <div className="col-12">
              <h1>Billing Address</h1>
            </div>
          </div>

          <div className="row">
            <div className="col-12 offset-0 col-sm-8 offset-sm-2">
              <hr />
              <div className="row">
                <div className="col-sm-6">
                  <fieldset id="account">
                    <div className="form-group" style={{ display: "none" }}>
                      <label className="control-label">Customer Group</label>
                      <div className="radio">
                        <label>
                          <input
                            type="radio"
                            name="customer_group_id"
                            defaultValue={1}
                            defaultChecked="checked"
                          />
                          Default
                        </label>
                      </div>
                    </div>
                    <div className="form-group required">
                      <input
                        onChange={(e) =>
                          handleChange(e, guestUser, setGuestUser)
                        }
                        value={guestUser.firstname}
                        type="text"
                        name="firstname"
                        className="form-control"
                        data-label="First Name"
                        placeholder="Enter First Name"
                      />
                    </div>
                    <div className="form-group required">
                      <input
                        onChange={(e) =>
                          handleChange(e, guestUser, setGuestUser)
                        }
                        value={guestUser.lastname}
                        type="text"
                        name="lastname"
                        className="form-control"
                        data-label="Last Name"
                        placeholder="Enter Last Name"
                      />
                    </div>
                    <div className="form-group required">
                      <input
                        onChange={(e) =>
                          handleChange(e, guestUser, setGuestUser)
                        }
                        value={guestUser.email}
                        type="email"
                        name="email"
                        className="form-control"
                        data-label="Email"
                        placeholder="Enter email"
                      />
                    </div>
                    <div className="form-group required">
                      <CountryCodes
                        cc={cc}
                        handleCountryCodeChange={handleCountryCodeChange}
                      />
                      <input
                        style={{ width: "60%", float: "right" }}
                        onChange={(e) =>
                          handleChange(e, guestUser, setGuestUser)
                        }
                        value={guestUser.telephone}
                        type="text"
                        name="telephone"
                        className="form-control"
                        data-label="Phone Number"
                        placeholder="Enter Phone Number"
                      />
                    </div>
                    <input
                      style={{ width: "69%", float: "right" }}
                      type="hidden"
                      name="telephone"
                      defaultValue=""
                      placeholder="Mobile Number"
                      id="input-payment-telephone1"
                    />
                    <div style={{ display: "none" }} className="form-group">
                      <label
                        className="control-label"
                        htmlFor="input-payment-fax"
                      >
                        Fax
                      </label>
                      <input
                        type="text"
                        name="fax"
                        defaultValue=""
                        placeholder=""
                        id="input-payment-fax"
                        className="form-control"
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="col-sm-6">
                  <fieldset id="address">
                    <div style={{ display: "none" }} className="form-group">
                      <label
                        className="control-label"
                        htmlFor="input-payment-company"
                      >
                        Company
                      </label>
                      <input
                        type="text"
                        name="company"
                        defaultValue=""
                        placeholder="Company"
                        id="input-payment-company"
                        className="form-control"
                      />
                    </div>
                    <div id="address_col" className="form-group required">
                      <input
                        onChange={(e) =>
                          handleChange(e, guestUser, setGuestUser)
                        }
                        value={guestUser.address_1}
                        type="text"
                        name="address_1"
                        className="form-control"
                        data-label="Address"
                        placeholder="Enter address"
                      />
                    </div>
                    <div style={{ display: "none" }} className="form-group">
                      <label
                        className="control-label"
                        htmlFor="input-payment-address-2"
                      >
                        Address 2
                      </label>
                      <input
                        type="text"
                        name="address_2"
                        defaultValue=""
                        placeholder="Address 2"
                        id="input-payment-address-2"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group required">
                      <div className="row">
                        <div
                          style={{ paddingLeft: 0 }}
                          className="col-sm-8 p-0"
                        >
                          <input
                            onChange={(e) =>
                              handleChange(e, guestUser, setGuestUser)
                            }
                            value={guestUser.city}
                            type="text"
                            name="city"
                            className="form-control"
                            data-label="City"
                            placeholder="Enter City"
                          />
                        </div>
                        <div id="pin_box" className="col-sm-4 p-0">
                          <input
                            onChange={(e) =>
                              handleChange(e, guestUser, setGuestUser)
                            }
                            value={guestUser.postcode}
                            type="text"
                            name="postcode"
                            className="form-control"
                            data-label="Postcode"
                            placeholder="Enter postcode"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group required">
                      <Select
                        onChange={(e) =>
                          handleCountryChange(e, guestUser, setGuestUser)
                        }
                        options={countries}
                        placeholder="Select Country"
                        name="country_id"
                        value={countries.filter(function (option) {
                          return option.value === guestUser.country_id;
                        })}
                      />
                    </div>
                    <div className="form-group required">
                      <Select
                        onChange={(e) =>
                          handleZoneChange(e, guestUser, setGuestUser)
                        }
                        options={zones}
                        placeholder={
                          zones.length === 0
                            ? "Please select country first"
                            : "Select State"
                        }
                        name="zone_id"
                        value={zones.filter(function (option) {
                          return option.value === guestUser.zone_id;
                        })}
                      />
                    </div>
                  </fieldset>
                </div>
              </div>

              <br />
              <div className="buttons">
                <div className="text-center">
                  <div>
                    <button
                      onClick={(e) => createGuestUser(e)}
                      disabled={buttonsDisabled}
                      className="btn btn-primary btn-gold"
                    >
                      {buttonsDisabled ? "Processing..." : "Confirm and Next"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <FooterDesktop /> */}
    </div>
  );
};

export default Billing;
