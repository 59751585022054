import React from "react";

const TrendingProductCard = (props) => {
  return (
    <>
      <img
              src={props.image}
              className="img-fluid"
              alt=""
              style={{ width: "100%" }}
            />
      <div className="product-card-body text-center">
        <h6
          className="mb-0 font-14"
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            
            overflow: "hidden",
            marginTop: "15px",
          }}
        >
          {props.name}
        </h6>
        <p className="mb-0">
          <b> {props.price}</b>
        </p>
      </div>
    </>
  );
};

export default TrendingProductCard;
