import React from "react";
import payment from "../../imgs/visa_list.png";
import { Link } from "react-router-dom";
import BottomNav from "./BottomNav";

const FooterMobile = (props) => {
  
  return (
    <footer className="mobile_footer pt-20 pb-60 off-bg">

    <div className="container">
      <div className="row">
      <div className="col-12 text-center">
              <h2>Sign Up For Newsletter</h2>
             <input type="text" placeholder="Your Email" className="subscribe-box-mobile" />
             <button className="subscribe-btn">Subscribe</button>
      </div>
      </div>
      <div className="rwo">
      <div className="accordion accordion-m border-0" id="accordion-group-5">
        <div className="accordion-item">
          <button
            className="accordion-button px-0 ps-1 collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#accordion5-1"
          >
            <span className="font-600 font-14 text-gold">
              About Compnay
            </span>
            <i className="bi bi-plus font-20 text-gold" />
          </button>
          <div
            id="accordion5-1"
            className="accordion-collapse"
            data-bs-parent="#accordion-group-5"
          >
            <div className="row">
              <div className="col-12">
                <Link to="../story" className="text-golden">
                  Our Story
                </Link>
                <br />
                <Link to="../login" className="text-golden">
                  Wishlist
                </Link>
                <br />
                <Link to="../login" className="text-golden">
                  My Account
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <button
            className="accordion-button px-0 ps-1 collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#accordion5-2"
          >
            <span className="font-600 font-14 text-gold">Customer Service</span>
            <i className="bi bi-plus font-20 text-gold" />
          </button>
          <div
            id="accordion5-2"
            className="accordion-collapse collapse"
            data-bs-parent="#accordion-group-5"
          >
            <div className="row">
              <div className="col-12">
              <Link to="../contact" >Contact Us</Link><br />
            <Link to="../terms" >Terms & Conditions</Link><br />
            <Link to="../privacy" >Privacy Policy</Link><br />
            <Link to="../returns" >Returns & Refund</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <button
            className="accordion-button px-0 ps-1 collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#accordion5-3"
          >
            <span className="font-600 font-14 text-gold">Get In Touch</span>
            <i className="bi bi-plus font-20 text-gold" />
          </button>
          <div
            id="accordion5-3"
            className="accordion-collapse collapse"
            data-bs-parent="#accordion-group-5"
          >
            <div className="row">
              <div className="col-12">
              <a tel="+919799332768" >
                    <i className="bi bi-phone"></i>   +91-97993 32768
                    </a>
                  
                    <br />
                    <a tel="+919950039573" >
                    <i className="bi bi-phone"></i>  +91-99500 39573
                    </a> <br />  
                    <Link to="" ><i className="bi bi-envelope"></i>&nbsp; info@codesilver.in</Link>
      
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
   
     

      <div className="row footer_links_social">
              <div className="col-12 mt-20 text-center">
                <p className="mb-0">&copy; 2022 All Rights Reserved</p>
                <img src={payment} alt="" className="img-fluid pay-links-mobile" />
            
              </div>
          </div>
    </div>
    <BottomNav page={props.page} />
  </footer>
    
  );
};

export default FooterMobile;
