import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CatalogContext } from "../../contexts";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  mobile: {
    breakpoint: { max: 650, min: 0 },
    items: 1.3,
  },
};

const MostwantedMobile = () => {
  const { allCategories } = useContext(CatalogContext);

  return (
    <section className="most-wanted dark-section pt-50 off-bg">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h3>Explore The Collection</h3>
            <p className="text-description">
              Wide Range Of Silver Artifacts To Choose From.
            </p>
          </div>
        </div>
        <div className="row">
          <Carousel responsive={responsive}>
            {allCategories.map((category) => {
              return (
                <>
                  <div className="col-12 mt-10">
                    <div className="test_box_mob box-01 col-12">
                      <div
                        className="inner"
                        style={{
                          backgroundImage: `url(${category.image})`
                        }}
                      >
                        <Link to={`/categories/${category.category_id}`} className="test_click">
                        <div className="flex_this text-center">
                          
                            <h1 className="test_title_mob">{category.name}</h1>
                       
                          <span className="test_link_mob">
                            Shop Now
                          </span>
                        </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </Carousel>
        </div>

        <br />
      </div>
    </section>
  );
};

export default MostwantedMobile;
