import React from "react";
import { Link } from "react-router-dom";

function hideSidebar() {
  document.getElementById("sidebar-bg").style.display = "none";
  document.getElementById("side-bar").style.left = "-270px";
}

const SideBar = () => {
  return (
    <>
      <div id="sidebar-bg" onClick={() => hideSidebar()}></div>
      <div id="side-bar">
        <i
          onClick={() => hideSidebar()}
          className="bi bi-x font-22 text-gold"
          id="close-sidebar"
        ></i>
        <nav className="mobile_nav">
          <Link to="../categories/87" className="golden-color">
             All Things Gifting
          </Link>
          <hr />
          <Link to="../categories/73" className="golden-color">
          Barware
          </Link>
          <hr />
         
          <Link to="../categories/83" className="golden-color">
          Hardware Collection
          </Link>
          <hr />
          <Link to="#" className="golden-color">
          Home & Decor<hr />
             <span>
              <Link className="golden-color" to="../categories/76">
              &nbsp;&nbsp; Art House
              </Link>
              <hr />
              <Link className="golden-color" to="../categories/88">
              &nbsp;&nbsp;  Furniture
              </Link>
              <hr />
              <Link className="golden-color" to="../categories/81">
              &nbsp;&nbsp; Photo Frames
              </Link>
              <hr />
              <Link className="golden-color" to="../categories/77">
              &nbsp;&nbsp;  Tea-light holder
              </Link>
             </span>
          </Link>
          <hr />
         
          <Link to="../categories/85" className="golden-color">
            Mauve By Code Silver
          </Link>
          <hr />
          <Link to="../categories/71" className="golden-color">
          Office Accessories
          </Link>
          <hr />
          <Link to="../categories/84" className="golden-color">
          Rakhi
          </Link>
          <hr />
          <Link to="../categories/82" className="golden-color">
          Religious Range
          </Link>
          <hr />
          <Link to="../categories/75" className="golden-color">
          Something Different
          </Link>
          <hr />
          <Link to="../categories/72" className="golden-color">
          Tableware
          </Link>
          <hr />
          <Link to="../categories/70" className="golden-color">
          Travel In Style
          </Link>
          <hr />
          <Link to="../categories/89" className="golden-color">
          Trophies & Mementos
          </Link>
          <hr />
          <Link to="../categories/74" className="golden-color">
          We love babies
          </Link>
        </nav>
      </div>
    </>
  );
};

export default SideBar;
